import { Link } from 'gatsby'
import React from 'react'
import FlyinNavigationContext from '../../context/flyinNavigationContext'
import { useContext } from 'react'
import { Location } from '@reach/router';
import {IoMdClose} from 'react-icons/io'
import {motion} from 'framer-motion'

export function FlyinNavigationComponent(locationProps) {
  
  const {isOpen, toggleNavigation} = useContext(FlyinNavigationContext)
  const path = locationProps.location.pathname

  const variants = {
    open: { opacity: 1},
    closed: { opacity: 1},
  }
  const parentVariants = {
    open: { 
      opacity: 1,
    },
    closed: { 
      opacity: 0,
    },
  }

  const childVariants = {
    initial:{
      y: 30,
      opacity: 0,
    },
    closed: {
      y: 30,
      opacity: 0,
    },
    open: {
      y: 0,
      opacity: 1,
      transition:{
        delay:0.25
      } 
    },
  };
  //console.log(isOpen)
  return (
    <div
    className={`w-full h-full fromZero:fixed z-[9999999999] ${isOpen === false ? ' pointer-events-none' : "pointer-events-all"}`}
    >   
      <div className={`${isOpen === false ? 'opacity-0 pointer-events-none' : "opacity-full pointer-events-all"} lg-custom:hidden fromZero:fixed top:0 left:0 w-full h-full text-white bg-sanaBlack font-mono text-2xl z-[9999999999] transition-all duration-500 `}>
        <IoMdClose className='absolute top-[2rem] left-[2rem]  z-[99999999999] cursor-pointer duration-500 transition-transform hover:rotate-180 text-white text-4xl' onClick={() => toggleNavigation()} />
        <motion.ul 
        className="nav-wrapper flex w-full h-full justify-center absolute top-0 left-0 content-center flex-wrap"
        variants={parentVariants}
        animate={isOpen ?  "open" : "closed"}
        initial="closed"
        >
          <motion.li
          variants={childVariants}
          animate={isOpen ?  "open" : "closed"}
          className={'relative w-full text-center mb-2'}
          >
            <Link to='/beer/lemon-gose/' className={`${path === '/beer/lemon-gose' ? "current-page" : ""} relative scrollLink button`}>Our Beer</Link>
          </motion.li>
          <motion.li
          variants={childVariants}
          animate={isOpen ?  "open" : "closed"}
          className={'relative w-full text-center mb-2'}
          >
            <Link to='/our-story/' className={`${path === '/our-story' ? "current-page" : ""} scrollLink button`}>Our Story</Link>
          </motion.li>
          <motion.li
          variants={childVariants}
          animate={isOpen ?  "open" : "closed"}
          className={'relative w-full text-center mb-2'}
          >
            <Link to='/blog/' className={`${path === '/blog' ? "current-page" : ""} button`}>Our Blog</Link>
          </motion.li>
          <motion.li
          variants={childVariants}
          animate={isOpen ?  "open" : "closed"}
          className={'relative w-full text-center mb-2'}
          >
            <Link to='/the-facts/' className={`${path === '/the-facts' ? "current-page" : ""} scrollLink button`}>The Facts</Link>
          </motion.li>
          <motion.li
          variants={childVariants}
          animate={isOpen ?  "open" : "closed"}
          className={'relative w-full text-center mb-2'}
          >
            <Link to='/the-body/' className={`${path === '/the-body' ? "current-page" : ""} scrollLink button`}>The Body</Link>
          </motion.li>
          <motion.li
          variants={childVariants}
          animate={isOpen ?  "open" : "closed"}
          className={'relative w-full text-center mb-2'}
          >
            <Link to='/the-mind/' className={`${path === '/the-mind' ? "current-page" : ""} scrollLink button`}>The Mind</Link>
          </motion.li>
          <motion.li
          variants={childVariants}
          animate={isOpen ?  "open" : "closed"}
          className={'relative w-full text-center mb-2'}
          >
            <Link to='/the-planet/' className={`${path === '/the-planet' ? "current-page" : ""} scrollLink button`}>The Planet</Link>
          </motion.li>
        </motion.ul>
      </div>
    </div>
  )
}


export default function FlyinNavigation(props) {
  return (
    <Location>
    {
      locationProps => <FlyinNavigationComponent {...locationProps} />
    }
    </Location>
  );
}
