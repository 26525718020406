import React, {useEffect, useRef, useState, useContext} from 'react';
import { Link } from "gatsby"
import Logo from '../../images/logo.inline.svg'
import Button from '../ui/Button';
import gsap from 'gsap'
import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {motion} from 'framer-motion'
import { Location } from '@reach/router';
import FlyinNavigationContext from '../../context/flyinNavigationContext'
import BackgroundColourContext from '../../context/backgroundColourContext'

gsap.registerPlugin(ScrollToPlugin);

export function HeaderComponent(locationProps){ 
  const path = locationProps.location.pathname
  const header = useRef()
  const { state } = useContext(SnipcartContext);
  const { toggleNavigation } = useContext(FlyinNavigationContext)
  
  let sanaBlack
  let sanaGrey
  let sanaBlackRaw
  let sanaGreyRaw

  if(typeof window !== 'undefined'){
    sanaBlackRaw = getComputedStyle(document.documentElement).getPropertyValue('--sanaBlack')
    sanaGreyRaw = getComputedStyle(document.documentElement).getPropertyValue('--sanaGrey')
    sanaBlack = sanaBlackRaw.substring(1, sanaBlackRaw.length-1)
    sanaGrey = sanaGreyRaw.substring(1, sanaGreyRaw.length-1)
  }

  const activeHeader = () => {
    const bg = header.current.querySelector('.header-bg')
    const logo = header.current.querySelectorAll('.logo svg path')
    const buttons = header.current.querySelectorAll('.buttons .button')
    header.current.classList.remove('black-header')
    header.current.classList.add('is-active')
    gsap.to(bg, {y:0, boxShadow:'7px 4px 12px rgba(0,0,0,0.2)'})
    gsap.to(logo, {fill:`${sanaBlack}`})
    gsap.to(buttons, {color:sanaBlack, duration:0.001})
  }
  const blackHeaderContent = () => {
    const logo = header.current.querySelectorAll('.logo svg path')
    const buttons = header.current.querySelectorAll('.buttons .button')
    header.current.classList.add('black-header')
    gsap.to(logo, {fill:`${sanaBlack}`})
    gsap.to(buttons, {color:sanaBlack, duration:0.001})
  }
  const inActiveHeader = () => {
    const bg = header.current.querySelector('.header-bg')
    const logo = header.current.querySelectorAll('.logo svg path')
    const buttons = header.current.querySelectorAll('.buttons .button')
    header.current.classList.remove('is-active')
    gsap.to(bg, {y:'-100%'})
    gsap.to(logo, {fill:`${sanaGrey}`})
    gsap.to(buttons, {color:sanaGrey})
  }
  
  const inActiveHeaderBackground = () => {
    const bg = header.current.querySelector('.header-bg')
    gsap.to(bg, {y:'-100%'})
  }

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    if(scrollPosition > 50){
      activeHeader()
    }else{
      inActiveHeader()
    }
  }

  const matchBlog = new RegExp(`\/blog\/[A-Za-z-]+`)
  const matchBeerSingle = new RegExp(`\/beer\/[A-Za-z-]+`)
  
  useEffect(() => {
    
    if(typeof window !== 'undefined'){
      // Show black header 
      if(path.match(matchBeerSingle)){
        blackHeaderContent()
      }
      
      if(
           path === '/shop' 
        || path === '/beer' 
        || path === '/the-body' 
        || path === '/the-mind' 
        || path === '/the-planet' 
        || path === '/our-story' 
        || path === '/the-facts'  
        || path === '/blog' 
        || path === '/privacy' 
        || path === '/terms' 
        
        || path === '/shop/' 
        || path === '/beer/' 
        || path === '/the-body/' 
        || path === '/the-mind/' 
        || path === '/the-planet/' 
        || path === '/our-story/' 
        || path === '/the-facts/'  
        || path === '/blog/' 
        || path === '/privacy/' 
        || path === '/terms/' 
      ){
        activeHeader()
      }

      if(path === '/' || path.match(matchBlog)){
        window.addEventListener('scroll', handleScroll)
      }
    }

    return () => {
      if(path === '/' || path.match(matchBlog)){
        window.removeEventListener('scroll', handleScroll)  
      }
    }
    
  },[])

  return(
    <motion.header
    initial={{ y: '-100%'}}
    animate={{ y: 0}}
    exit={{ y: '-100%' }}
    transition={{
      type: "spring",
      mass: 0.35,
      stiffness: 75,
      duration: 0.3
    }}
    >
      <div ref={header} className='fromZero:px-4 md:px-8 fromZero:py-4  py-6 header flex justify-center items-center content-center text-white font-heading relative w-screen'>
        <div className="header-bg absolute w-screen h-full bg-sanaGrey z-0 -translate-y-full"></div>
      
        <Link to="/" className="z-10 logo w-auto mx-auto">
          <Logo className="w-[100px] md:w-[120px]"/>
          <div className="hidden">Link to home</div>
        </Link>
       
      </div>
    </motion.header>
  )

}

export default function Header(props) {
  return (
    <Location>
    {
      locationProps => <HeaderComponent {...locationProps} />
    }
    </Location>
  );
}
