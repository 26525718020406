import React, {useEffect, useContext} from "react"
import {motion} from 'framer-motion'
import Header from "./global/Header"
import Footer from "./global/Footer"
import NotificationBanner from "./global/NotificationBanner"
import NewsletterSignup from "./global/NewsletterSignup"
import FlyinNavigation from "./global/FlyinNavigation"
import { FlyinNavigationProvider } from "../context/flyinNavigationContext"
import { BackgroundColourProvider } from "../context/backgroundColourContext"
import { Helmet } from "react-helmet"


export default function Layout({ path, children }) {
  
  const calculateFullHeightContainers = () => {
    if(typeof window !== 'undefined'){
      const fullHeightDivs = Array.from(document.querySelectorAll('.fix-full-height'))
      const windowHeight = window.innerHeight
      let vh = windowHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      fullHeightDivs.map(item => {
        
        item.style.height = windowHeight+'px';
      })
    }
  }
  

  useEffect(() => {
    calculateFullHeightContainers()
  })

  return (
    <>
      <FlyinNavigationProvider>
        <div className="fixed top-0 left-0  z-[99999999]">
          {/* <NotificationBanner /> */}
          <Header />
        </div>
        <FlyinNavigation />
        <Helmet htmlAttributes={{
          lang: 'en',
        }}/>
        <motion.main
          initial={{ opacity: 0}}
          animate={{ opacity: 1}}
          exit={{ opacity: 0 }}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.3
          }}
        >
          {children}
          <NewsletterSignup />
          <Footer />
        </motion.main>
      </FlyinNavigationProvider>
    </>
  )
}