import React from 'react';
import { Link } from 'gatsby';
export default function Footer() {

  const now = new Date()
  const year = now.getFullYear()

  return (
    <footer className="py-4 px-8 text-xs flex flex-wrap justify-between uppercase z-40 bg-sanaGrey relative">
      <div className='w-full sm:w-auto mb-2 sm:mb-0'>&copy; Sana Beer Co {year}</div>
      {/* <Link className="w-full sm:w-auto mb-2 sm:mb-0 underline" to="/privacy">Privacy</Link>
      <Link className="w-full sm:w-auto mb-2 sm:mb-0 underline" to="/terms">Terms &amp; Conditions</Link> */}
      <a className="w-full sm:w-auto mb-2 sm:mb-0 underline" href="mailto:hello@sanabeer.uk">Contact us</a>
    </footer>
  )
}
