import React from 'react'
import { Helmet } from 'react-helmet'

export default function Seo({title, description}) {
  return (
    <Helmet defer={false}>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}
