import React, {useState, useRef} from "react"
import { StaticQuery, graphql } from "gatsby"
import Topography from '../../images/topography-rectangle.jpg'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { GatsbyImage } from "gatsby-plugin-image"

export default function NewsletterSignup() {
  const [email, setEmail] = useState()
  const [subscribed, setSubscribed] = useState(false)
  const [checkboxValue, setCheckboxValue] = useState(false)
  const checkbox = useRef()

  const handleSetEmail = (e) => {
    const value = e.target.value
    setEmail(value)
  }

  const handleSetCheckboxValue = () => {
    setCheckboxValue(checkbox.current.checked)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
    .then(data => {
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      //console.log(data)
      setSubscribed(true)
    })
  }


  return (
    <StaticQuery
      query={graphql`
        query NewsletterSignupQuery {
          prismicGlobals {
            data {
              newsletter_heading {
                text
              }
              newsletter_text {
                text
              }
              map_square_pale {
                gatsbyImageData
              }
            }
          }
        }
      `}
      render={data => (
        <section id="newsletter" className="relative w-screen bg-sanaBlack px-8 py-8 lg:py-24 text-left text-white overflow-hidden">  
          <div className="max-w-[1100px] mx-auto flex flex-wrap lg:flex-nowrap z-10 relative">
            <div className="lg:max-w-[350px]">
              <h3 className=" uppercase font-heading font-light text-4xl  mb-8">{data.prismicGlobals.data.newsletter_heading.text}</h3>
              <div className="max-w-[350px]">{data.prismicGlobals.data.newsletter_text.text}</div>
            </div>
            {subscribed === true ? 
              <div className="ml-auto w-full lg:w-auto mt-8 lg:mt-0 text-2xl font-heading uppercase font-light">
                Thanks for subscribing!
              </div>
            : 
              <form className="ml-auto w-full lg:w-auto mt-8 lg:mt-0" onSubmit={(e) => handleSubmit(e)}>
                <div className="relative block mb-8 w-full lg:max-w-[350px]">
                  <input onKeyUp={(e) => handleSetEmail(e)} className="w-full bg-sanaBlack border-b-[rgba(255,255,255,0.8)] transition-all placeholder-shown:border-b-[rgba(255,255,255,0.2)] valid:border-b-green-500 valid:text-green-500 border-b peer text-2xl font-heading font-light placeholder-transparent p-4 px-0 w-full p-x-0 lg:min-w-[400px] focus:outline-0 outline-0"  type="email" name="email" id="email"  required placeholder="Enter Email Address"/>
                  <label className="absolute left-0 text-2xl font-heading uppercase font-light peer-placeholder-shown:top-4 -top-8 transition-all " htmlFor="email">Enter Email Address</label>
                </div>
                <div className="relative flex items-center mb-8">
                  <label className="inline-flex items-center cursor-pointer">
                    <div className="relative ">
                      <input ref={checkbox} onChange={() => handleSetCheckboxValue()} type="checkbox" className="peer w-5 h-5 bg-transparent appearance-none hidden" />
                      <div className="w-5 h-5 border-2 border-white rounded-md before:w-2 before:inset-1.5  before:h-2 before:bg-sanaRed before:rounded-full before:content-[''] before:absolute before:opacity-0 before:scale-0 peer-checked:before:scale-125 peer-checked:before:opacity-100 before:transition-all"></div>
                    </div>
                    <span className="ml-2 text-sm">I accept being added to a mailing list.</span>
                  </label>
                </div>
                <input type="submit" value="Sign me up!" className={`bg-sanaBlack text-white border-2 border-white rounded-full font-heading tracking-wide uppercase px-4 py-2 font-lg  hover:text-sanaBlack hover:bg-white duration-500 cursor-pointer ${checkboxValue === true ? 'block opacity-100' : 'hidden opacity-0'}`} />
              </form>
            }
          </div>
          <GatsbyImage image={data.prismicGlobals.data.map_square_pale.gatsbyImageData} className={`pointer-events-none absolute top-0 left-0 w-full lg:w-1/2 top-0 left-0 h-full object-cover block mix-blend-difference z-0`} alt={`Map image`}/>
        </section>
      )}
    />
  )
}