import React, { createContext, useState } from "react";

const FlyinNavigationContext = createContext();

export function FlyinNavigationProvider({children}){
  const [isOpen, setIsOpen] = useState(false)

  const toggleNavigation = () => {
    setIsOpen(!isOpen)
  }

  return (
    <FlyinNavigationContext.Provider 
      value={{
        isOpen, 
        toggleNavigation
      }}>
        {children}
    </FlyinNavigationContext.Provider>
  )
}
export default FlyinNavigationContext