import React, {useEffect} from 'react';
import HydrateAndHeal from '../../../images/hydrate-heal.inline.svg'
import Button from '../../ui/Button';
import {FaInstagramSquare, FaTwitterSquare} from 'react-icons/fa'
import Line from '../../../images/vert-arrow.inline.svg'
import { StaticQuery, graphql } from "gatsby"
import { RellaxWrapper } from 'react-rellax-wrapper'

export default function Landing() {
  useEffect( () => {
    var video =  document.querySelectorAll('video')
    //add source to video tag
    function addSourceToVideo(element, src) {
        var source = document.createElement('source');
        source.src = src;
        source.type = 'video/mp4';
        //console.log(src);
        element.appendChild(source);
    }

    //determine screen size and select mobile or desktop vid
    function whichSizeVideo(element, src) {
      var windowWidth = window.innerWidth;
      if (windowWidth < 5000 && windowWidth > 1080 ) {
        addSourceToVideo( element, src.dataset.xlVid);
      }
      else if (windowWidth < 1080 && windowWidth > 960 ) {
        addSourceToVideo( element, src.dataset.lVid);
      }
      else if (windowWidth < 960 && windowWidth > 640 ) {
        addSourceToVideo( element, src.dataset.mVid);
      }
      else {
        addSourceToVideo(element, src.dataset.sVid);
      }
    }

    //init only if page has videos
    function videoSize() {
      if (video !== undefined) {
      video.forEach(function(element, index) {
          whichSizeVideo(  
            element, //element
            element  //src locations
          );	
        });
      }
    }
    videoSize();
  })
  return (
    <StaticQuery
      query={graphql`
        query SocialsQuery {
          landing: prismicHome {
            data {
              background_video_1920_hd
              background_video_1080_hd
              background_video_960_sd
              background_video_640_sd
              title{
                text
              }
              subtitle{
                text
              }
              button_text
              button_link{
                uid
              }
              bottom_text
            }
          }
          prismicGlobals {
            data {
              instagram
              twitter
            }
          }
        }
      `}
      render={data => (
        <section id="landing" className='relative fix-full-height w-screen h-screen bg-sanaBlack flex items-center justify-center z-0 overflow-hidden'>
          <div className="m-auto w-screen absolute z-[100] text-center text-white">
            <h2 className='font-heading uppercase font-light text-l sm:text-xl md:text-2xl lg:text-3xl mb-4 md:mb-8 max-w-[223px] sm:max-w-[10000px] mx-auto'>{data.landing.data.subtitle.text}</h2>
            <HydrateAndHeal className="z-10 m-auto min-w-[280px] max-w-[1000px] w-[calc(100%-2rem)] md:w-3/5 h-auto mb-10 md:mb-20" />
            {/* <Button text={data.landing.data.button_text} link={data.landing.data.button_link.uid} size={4} state={'primary'} classes={'px-3.5 sm:px-8 py-2 sm:py-4'} /> */}
          </div>        
          <div className="absolute z-10 text-white font-heading bottom-0  mb-9 md:mb-12">
            <Line className="mx-auto mb-8 animate-bounce" />
            Coming soon
          </div>
          <a href={data.prismicGlobals.data.instagram} target="_blank" rel="noreferrer" className='group'>
            <FaInstagramSquare className="absolute z-[100] bottom-8 left-4 text-white text-3xl duration-500 group-hover:scale-125" />
          </a>
          <a href={data.prismicGlobals.data.twitter} target="_blank" rel="noreferrer" className='group'>
            <FaTwitterSquare className="absolute z-[100] bottom-8 right-4 text-white text-3xl duration-500 group-hover:scale-125" />
          </a>
          <div className="fix-full-height w-screen h-screen">
            <RellaxWrapper speed={-10} percentage={0.5} className="absolute">
              <div className="fix-full-height w-screen h-screen mix-blend-multiply absolute top-0 left-0 z-10 opacity-80 bg-gradient-to-b from-sanaBlack to-transparent"></div>
              <video 
              id="bgvid" 
              preload="auto" 
              muted 
              autoPlay 
              loop
              playsInline
              className='w-screen h-screen object-cover pointer-events-none'
              data-xl-vid={data.landing.data.background_video_1920_hd}
              data-l-vid={data.landing.data.background_video_1080_hd}
              data-m-vid={data.landing.data.background_video_960_sd} 
		          data-s-vid={data.landing.data.background_video_640_sd}>
	            </video>
            </RellaxWrapper>
          </div>
        </section>
      )}
    />
   
  )
}
