import React from 'react';
import Seo from '../components/global/Seo';
import Layout from '../components/Layout';
import Landing from '../components/pages/homepage/Landing';

export default function Homepage() {
  return (
  <Layout>
    <Seo title={'Sana Beer | Homepage'} description={'Sana Beer'} />
    <Landing /> 
  </Layout>
  )
}
